import React, { createContext } from "react";
import { useMemo } from "react";
import { useContext } from "react";

const CURRENCYI_CONFIG_KEY = process.env.REACT_APP_CURRENCY_CONFIG_KEY || "CURRENCY_Current";

const initialState = {
    selectedCurrency: "EUR"
};

function getConfig() {
    const lSConfig = localStorage.getItem(CURRENCYI_CONFIG_KEY);
    if (lSConfig) {
        try {
            return JSON.parse(lSConfig);
        } catch (error) {
            console.error('Error fetching locale from localStorage ', error);
        }
    }
    return initialState;
}

export function setCurrency(currency) {
    localStorage.setItem(CURRENCYI_CONFIG_KEY, JSON.stringify({ selectedCurrency: currency }));
    window.location.reload();
}

export const availableCurrencies = [
    'EUR', 'USD', 'GBP', 'AED', 'ARS', 'BRL', 'CHF', 'CLP', 'COP', 'MXN', 'TRY'
];

const CurrencyContext = createContext();

export function useCurrency() {
    return useContext(CurrencyContext).selectedCurrency;
}

export function withCurrency(Component) {
    class AddCurrency extends React.Component {
        static displayName = `LinkedWithCurrency(${Component.displayName || Component.name})`;

        static contextType = CurrencyContext;

        render() {
            return <Component {...this.props} menu={this.context} />;
        }
    }
    return AddCurrency;
}

export const CurrencyConsumer = CurrencyContext.Consumer;

export function CurrencyProvider({ children }) {
    const currency = useMemo(getConfig, []);

    return (
        <CurrencyContext.Provider value={currency}>
            {children}
        </CurrencyContext.Provider>
    );
}
