import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    meetingRoomBook: null,
    error: null,
    prebookMeta: null,
};

export const meetingRoomBookingSlice = createSlice({
    name: "meetingRoomBooking",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        prebookCreated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.prebookMeta = action.payload.prebookMeta;
        },

        meetingRoomBookCreated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomBook = action.payload.meetingRoomBook;
        },

        resetState: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomBook = null;
            state.prebookMeta = null;
        }
    }
});
