import React from 'react';
import { Provider } from "react-redux";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from 'assets/style/Global.style';
import {
  I18nProvider,
  MessagesProvider,
  AntdConfigProvider
} from 'context/i18n';
import AuthProvider from 'context/AuthProvider';
import store from "./redux/store";
import Routes from './router';
import { CurrencyProvider } from 'context/CurrencyProvider';
import { BrandConfigProvider } from 'context/BrandConfigProvider';

const { PUBLIC_URL } = process.env;
const App = () => (
  <I18nProvider>
    <Provider store={store}>
      <BrandConfigProvider>
        <React.Fragment>
          <GlobalStyles />
          <CurrencyProvider>
            <BrowserRouter basename={PUBLIC_URL}>
              <AuthProvider>
                <AntdConfigProvider>
                  <MessagesProvider>
                    <Routes />
                  </MessagesProvider>
                </AntdConfigProvider>
              </AuthProvider>
            </BrowserRouter>
          </CurrencyProvider>
        </React.Fragment>
      </BrandConfigProvider>
    </Provider>
  </I18nProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
