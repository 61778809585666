import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    domainFrontConfig: null,
    loading: false,
    loadingPolices: false,
    error: null,
    lastDomain: null,
    lastLang: null,
    timeStamp: 0
};

export const frontConfigByDomainSlice = createSlice({
    name: "frontConfigByDomain",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
            state.timeStamp = new Date().getTime();
        },

        startCall: (state) => {
            state.error = null;
            state.loading = true;
        },

        frontConfigFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.domainFrontConfig = action.payload.frontConfig;
            state.lastLang = action.payload.langRequested
            state.lastDomain = action.payload.domainRequested;
            state.timeStamp = new Date().getTime();
        },
    }
});
