import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomCancelBooking: null,
    error: null
};

export const roomCancelBookingSlice = createSlice({
    name: "roomCancelBooking",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        roomCancelBookingFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomCancelBooking = action.payload.roomCancelBooking;
        },
    }
});
