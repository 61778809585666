import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    meetingRoomCancelBooking: false,
    error: null
};

export const meetingRoomCancelBookingSlice = createSlice({
    name: "meetingRoomCancelBooking",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
            state.meetingRoomCancelBooking = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.meetingRoomCancelBooking = false;
        },

        meetingRoomCancelBookingFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomCancelBooking = true;
        },
    }
});
