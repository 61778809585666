import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    meetingRooms: [],
    meetingRoom: [],
    error: null,
};

export const meetingRoomSlice = createSlice({
    name: "meetingRoom",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startFetchMeetingRoomsCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.meetingRooms = [];
        },

        meetingRoomsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRooms = action.payload.meetingRooms;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        meetingRoomFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.meetingRoom = action.payload.meetingRoom;
        },
    }
});
