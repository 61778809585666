import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    meetingRoomSuccessPayment: null,
    error: null
};

export const meetingRoomSuccessPaymentSlice = createSlice({
    name: "meetingRoomSuccessPayment",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        meetingRoomSuccessPaymentFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomSuccessPayment = action.payload.meetingRoomSuccessPayment;
        },
        
        resetState: (state, action) => {
            state.loading = false;
            state.meetingRoomSuccessPayment = null;
            state.error = null;
        }
    }
});
