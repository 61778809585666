import React, { useState, useRef } from 'react';
import { Menu } from 'antd';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { useLang, setLanguage } from 'context/i18n/I18nProvider';

const languages = [
    {
        lang: "es",
        label: "ESP",
    },
    {
        lang: "en",
        label: "ENG",
    },
    {
        lang: "fr",
        label: "FR",
    },
    {
        lang: "de",
        label: "DE",
    },
    {
        lang: "it",
        label: "ITA",
    }
];

const LanguageMenu = () => {
    const lang = useLang();
    const currentLanguage = languages.find((language) => language.lang === lang);

    const [state, setState] = useState(false);

    const dropdownRef = useRef(null);
    useOnClickOutside(dropdownRef, () => setState(false));

    const handleDropdown = () => {
        setState(!state);
    };

    const handleSelectLanguage = (language) => {
        setLanguage(language.lang);
    }

    return (
        <div className="language-dropdown" ref={dropdownRef}>
            <div className="dropdown-handler" onClick={handleDropdown}>
                {currentLanguage.label}
            </div>

            <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`}>
                {languages.map((language) => (
                    <Menu.Item key={language.lang}>
                        <button onClick={() => handleSelectLanguage(language)}>
                            {language.label}
                        </button>
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    );
}

export default LanguageMenu;
