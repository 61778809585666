import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    availability: [],
    roomAvailability: [],
    error: null
};

export const availabilitySlice = createSlice({
    name: "availability",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startFetchAvailabilityCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.availability = [];
        },

        availabilityFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.availability = action.payload.availability;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        roomAvailabilityFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomAvailability = action.payload.roomAvailability;
        },
    }
});
