import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';



const ContentStyler = styled.div`
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
dl,
th,
dt,
input,
textarea,
span,
div {
  font-family: ${themeGet('fontFamily.primary', '')};
}
`;
export default ContentStyler;