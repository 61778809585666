import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    hotelsWithMeetingRooms: [],
    hotel: null,
    lastError: null,
};

export const hotelsSlice = createSlice({
    name: "hotel",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        hotelsWithMeetingRoomsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.hotelsWithMeetingRooms = action.payload.hotelsWithMeetingRooms;
        },

        hotelFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.hotel = action.payload.hotel;
        },
    }
});
