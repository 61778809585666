import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    meetingRoomFindBooking: null,
    error: null,
    meetingRoomBookingError: false
};

export const meetingRoomFindBookingSlice = createSlice({
    name: "meetingRoomFindBooking",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
            state.meetingRoomBookingError = true;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.meetingRoomBookingError = false;
        },

        meetingRoomFindBookingFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomFindBooking = action.payload.meetingRoomFindBooking;
            state.meetingRoomBookingError = false;
        },
    }
});
