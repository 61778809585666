import React from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import {
  MEETING_ROOM_HOTELS
} from 'settings/constant';

const MainMenu = ({ className, website, intl }) => {
  return (
    <Menu className={className}>
      {website &&
        <Menu.Item key="0">
          <a href={website}>{intl.formatMessage({ id: "HOTEL_BACK" })}</a>
        </Menu.Item>}
      <Menu.Item key="1" style={{display: 'none'}}>
        <NavLink to={`${MEETING_ROOM_HOTELS}`}>Salas</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default injectIntl(MainMenu);
