import { frontConfigByDomainSlice } from "./configByDomainSlice";
import * as configByDomainService from "./configByDomainService";
import * as configByDomainServicesMap from "./configByDomainServicesMap";

const { actions } = frontConfigByDomainSlice;

export const fetchConfigByDomain = (queryParams) => async dispatch => {
    const { domain, lang } = queryParams;
    dispatch(actions.startCall());
    try {
        const response = await configByDomainService.getFrontConfig(queryParams);
        dispatch(actions.frontConfigFetched(configByDomainServicesMap.fetchFrontConfigMap(response.data, domain, lang)));
    } catch (error) {
        error.clientMessage = "LAYHOUR.ERRORS.ERROR_LOADING_BRAND";
        dispatch(actions.catchError({ error }));
    }
};
