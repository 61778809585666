export function fetchFrontConfigMap(data, domain, lang) {
     return {
          frontConfig: filterObjectProps(data),
          domainRequested: domain,
          langRequested: lang
     };
}

const filterObjectProps = (source) => {
     return Object.keys(source)
          .reduce((toResult, key) => {
               (!key.startsWith('@')) && (toResult[key] = source[key]);
               return toResult;
          }, {});
}
