export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

//Rooms pages
export const HOME_PAGE = '/'; //list of hotels with rooms
export const ROOMS = '/hotel/rooms';
export const ROOM_DETAIL = '/hotel/room/detail';
export const ROOM_CHECK_OUT = '/hotel/room/check-out';
export const ROOM_SUCCESS = '/hotel/room/success';
export const ROOM_ERROR = '/hotel/room/error';
export const ROOM_CANCEL_BOOKING = '/hotel/room/cancel';

//Meeting Room pages
export const MEETING_ROOM_HOTELS = '/chain/meeting-room-hotels';
export const MEETING_ROOMS = '/hotel/meeting-rooms';
export const MEETING_ROOM_DESTINATIONS = '/destination/meeting-rooms';
export const MEETING_ROOM_DETAIL = '/hotel/meeting-room/detail';
export const MEETING_ROOM_CHECK_OUT = '/hotel/meeting-room/check-out';
export const MEETING_ROOM_SUCCESS = '/hotel/meeting-room/success';
export const MEETING_ROOM_ERROR = '/hotel/meeting-room/error';
export const MEETING_ROOM_CANCEL_BOOKING = '/hotel/meeting-room/cancel';

//widget
export const ROOM_WIDGET_PREVIEW = '/widget/preview/room';
export const MEETING_ROOM_WIDGET_PREVIEW = '/widget/preview/meeting-room';


export const AGENTS_PAGE = '/agents';
// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

export const DISPLAY_MOMENT_DATE_FORMAT = "DD-MM-YYYY";
export const MOMENT_TIME_FORMAT = "h:mm";
export const MOMENT_TIME_MILITARY_FORMAT = 'HH:mm';
export const API_MOMENT_DATE_FORMAT = "YYYY-MM-DD";
export const API_MOMENT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const INVALID_DATE = "Invalid Date";
export const MIN_HOUR_TO_NEXT_DAY = 15;
export const INITIAL_START_HOUR = '09:00';
export const INITIAL_END_HOUR = '12:00';
export const INITIAL_ATTENDEES = 2;

export const DEFAULT_SECONDARY_COLOR = '#00000';
export const DEFAULT_PRIMARY_COLOR = '#FA8213';
export const DEFAULT_FONT_FAMILY = 'Roboto, "Helvetica Neue", Arial, sans-serif';
export const DEFAULT_FONT_SIZE = 12;


export const ROOMS_PACKS_HOURS = [
    { label: '3H', value: 3 },
    { label: '6H', value: 6 },
    { label: '24H', value: 24 }
];

export const BY_HOURS_PACK_ID = 4;

export const PACK_TYPE_OPTIONS = [
    { label: 'MEETIG_ROOMS.PACK.MORNING', value: 1 },
    { label: 'MEETIG_ROOMS.PACK.AFTERNOON', value: 2 },
    { label: 'MEETIG_ROOMS.PACK.WORKING_DAY', value: 3 },
    { label: 'MEETIG_ROOMS.PACK.BY_HOURS', value: BY_HOURS_PACK_ID }
];

export const CURRENCIES = [
    { "id": "AED", "symbol": "AED" },
    { "id": "ARS", "symbol": "AR$" },
    { "id": "BRL", "symbol": "R$" },
    { "id": "CHF", "symbol": "CHF" },
    { "id": "CLP", "symbol": "CLP" },
    { "id": "COP", "symbol": "COP" },
    { "id": "EUR", "symbol": "€" },
    { "id": "GBP", "symbol": "£" },
    { "id": "MXN", "symbol": "MXN" },
    { "id": "TRY", "symbol": "TL" },
    { "id": "USD", "symbol": "$" }];

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const TAG_MANAGER_MEETING_ROOM = process.env.REACT_APP_GOOGLE_TAG_MANAGER_MEETING_ROOM;
export const TAG_MANAGER_ROOM = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ROOM;
export const MEETING_ROOM_WIDGET_CHANNEL = 6;