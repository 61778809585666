/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Layout as LayoutWrapper } from 'antd';
import useWindowSize from 'library/hooks/useWindowSize';
import LayoutProvider from 'context/LayoutProvider';
import Loader from 'components/Loader/Loader';
import ContentStyler from './Layout.style';
import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  ROOM_DETAIL,
  CHANGE_PASSWORD_PAGE,
  FORGET_PASSWORD_PAGE,
} from 'settings/constant';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import * as frontConfigActions from "redux/front-config-by-domain/configByDomainActions";
import { useLang } from 'context/i18n/I18nProvider';

const { Content } = LayoutWrapper;

export default withRouter(function Layout({ children, location }) {
  const { width } = useWindowSize();
  const singlePageUrlFromConst = ROOM_DETAIL.split('/');
  const singlePageUrlFormLocation = location.pathname.split('/');
  const searchParams = new URLSearchParams(window.location.search);
  const domain = window.location.hostname;
  const currentLang = useLang();
  const searchLang = searchParams.get('lang') || currentLang;
  const showHeaderParam = searchParams.get('showHeader');
  const showFooterParam = searchParams.get('showFooter');
  const showHeader = showHeaderParam ? (showHeaderParam === "true") : true;
  const showFooter = showFooterParam? (showFooterParam === "true") : true;

  const { configForDomain, configDomain, configLang, configTimeStamp, listLoading, error } = useSelector(
    (state) => ({
      configForDomain: state.frontConfigByDomain.domainFrontConfig,
      configLang: state.frontConfigByDomain.lastLang,
      configDomain: state.frontConfigByDomain.lastDomain,
      configTimeStamp: state.frontConfigByDomain.timeStamp,
      listLoading: state.frontConfigByDomain.loading,
      error: state.frontConfigByDomain.error,
    }), shallowEqual
  );

  const diffTimeStampWithConfig = new Date().getTime() - configTimeStamp;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if ((!listLoading && (error === null))
      && ((configForDomain === null) || (configLang !== searchLang) || (configDomain !== domain))
      && (showHeader && showFooter)) {
      dispatch(frontConfigActions.fetchConfigByDomain({ domain: domain, lang: searchLang }));
    }
  }, [domain, searchLang, diffTimeStampWithConfig]);

  return (
    <React.Fragment>
      {(listLoading) && (
        <Loader />
      )}

      {(error) && (
        <FormattedMessage id={error.clientMessage} />
      )}

      {(configForDomain || !(showHeader || showFooter)) && (
        <LayoutProvider>
          {((location.pathname === LOGIN_PAGE)
            || (location.pathname === CHANGE_PASSWORD_PAGE)
            || (location.pathname === FORGET_PASSWORD_PAGE)
            || (location.pathname === REGISTRATION_PAGE)) ? (
              <Content>
                {children}
              </Content>
            ) : (
              <React.Fragment>
                {(showHeader) && (
                  <Header website={configForDomain.website}
                    logoUrl={configForDomain.logoUrl}
                    headerText={configForDomain.header} />
                )}

                <Content>
                  <ContentStyler>
                    {children}
                  </ContentStyler>
                </Content>

                {(showFooter) && (
                  <React.Fragment>
                    <Footer siteConfigs={configForDomain} />
                    {(singlePageUrlFormLocation[1] === singlePageUrlFromConst[1]) && (
                      <React.Fragment>
                        {(width < 1200) && (
                          <div style={{ height: '74px' }} />
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

              </React.Fragment>
            )}
        </LayoutProvider>
      )}
    </React.Fragment>
  );
});
