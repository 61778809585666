import React, { useState, useRef } from 'react';
import { Menu } from 'antd';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { availableCurrencies, useCurrency, setCurrency } from 'context/CurrencyProvider';

const CurrencyMenu = () => {
    const currencies = availableCurrencies;
    const currentCurrency = useCurrency();

    const [state, setState] = useState(false);

    const dropdownRef = useRef(null);
    useOnClickOutside(dropdownRef, () => setState(false));

    const handleDropdown = () => {
        setState(!state);
    };

    const handleSelectCurrency = (currency) => {
        setCurrency(currency);
    }

    return (
        <div id="dropdown" className="currency-dropdown" ref={dropdownRef}>
            <div className="dropdown-handler" onClick={handleDropdown}>
                {currentCurrency}
            </div>

            <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`}>
                {currencies.map((currency) => (
                    <Menu.Item key={currency}>
                        <button onClick={() => handleSelectCurrency(currency)}>
                            {currency}
                        </button>
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    );
}

export default CurrencyMenu;
