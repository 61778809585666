import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomAvailability: null,
    error: null
};

export const hotelHoursSlice = createSlice({
    name: "hotelHours",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.roomAvailability = null;
        },

        hotelHoursFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomAvailability = action.payload.roomAvailability;
        },
    }
});
