import React, { useContext } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { AuthContext } from './context/AuthProvider';
import Layout from './container/Layout/Layout';
import {
  LOGIN_PAGE,
  HOME_PAGE,
  ROOMS,
  ROOM_DETAIL,
  ADD_HOTEL_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  PRIVACY_PAGE,
  PRICING_PLAN_PAGE,
  MEETING_ROOM_HOTELS,
  MEETING_ROOMS,
  MEETING_ROOM_DETAIL,
  ROOM_CHECK_OUT,
  ROOM_SUCCESS,
  ROOM_ERROR,
  ROOM_CANCEL_BOOKING,
  MEETING_ROOM_SUCCESS,
  MEETING_ROOM_ERROR,
  MEETING_ROOM_CHECK_OUT,
  MEETING_ROOM_CANCEL_BOOKING,
  ROOM_WIDGET_PREVIEW,
  MEETING_ROOM_WIDGET_PREVIEW,
  MEETING_ROOM_DESTINATIONS
} from './settings/constant';

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import('./container/Rooms/Hotels/Hotels'),
      loading: Loading,
      modules: ['Rooms'],
    }),
    exact: true,
  },
  {
    path: ROOMS,
    component: Loadable({
      loader: () =>
        import('./container/Rooms/Rooms/Rooms'),
      loading: Loading,
      modules: ['Rooms'],
    }),
  },
  {
    path: `${ROOM_DETAIL}/:slug`,
    component: Loadable({
      loader: () =>
        import('./container/Rooms/RoomDetail/SinglePageView'),
      loading: Loading,
      modules: ['Rooms'],
    }),
  },
  {
    path: ROOM_CHECK_OUT,
    component: Loadable({
      loader: () =>
        import('./container/Rooms/RoomCheckOut/CheckOutForm'),
      loading: Loading,
      modules: ['Rooms'],
    }),
  },
  {
    path: ROOM_SUCCESS,
    component: Loadable({
      loader: () =>
        import('./container/Rooms/RoomSuccess/Details'),
      loading: Loading,
      modules: ['Rooms'],
    }),
  },
  {
    path: ROOM_ERROR,
    component: Loadable({
      loader: () =>
        import('./container/Rooms/RoomError/Details'),
      loading: Loading,
      modules: ['Rooms'],
    }),
  },
  {
    path: ROOM_CANCEL_BOOKING,
    component: Loadable({
      loader: () =>
        import('./container/Rooms/RoomCancelBooking/CancelBookingFormDetail'
        ),
      loading: Loading,
      modules: ['Details'],
    }),
  },
  {
    path: MEETING_ROOM_HOTELS,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/Hotels/Hotels'),
      loading: Loading,
      modules: ['MeetingRooms'],
    }),
    exact: true,
  },
  {
    path: MEETING_ROOM_DESTINATIONS,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/Destinations/MeetingRoomsDestinations'),
      loading: Loading,
      modules: ['MeetingRooms'],
    }),
    exact: true,
  },
  {
    path: MEETING_ROOMS,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/MeetingRooms/MeetingRooms'),
      loading: Loading,
      modules: ['MeetingRooms'],
    }),
  },
  {
    path: `${MEETING_ROOM_DETAIL}/:slug`,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/MeetingRoomDetail/SinglePageView'),
      loading: Loading,
      modules: ['MeetingRooms'],
    }),
  },
  {
    path: MEETING_ROOM_CHECK_OUT,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/MeetingRoomCheckOut/CheckOutForm'),
      loading: Loading,
      modules: ['MeetingRooms'],
    }),
  },
  {
    path: MEETING_ROOM_SUCCESS,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/MeetingRoomSuccess/Details'),
      loading: Loading,
      modules: ['MeetingRooms'],
    }),
  },
  {
    path: MEETING_ROOM_ERROR,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/MeetingRoomError/Details'),
      loading: Loading,
      modules: ['MeetingRooms'],
    }),
  },
  {
    path: MEETING_ROOM_CANCEL_BOOKING,
    component: Loadable({
      loader: () =>
        import('./container/MeetingRooms/MeetingRoomCancelBooking/CancelBookingFormDetail'
        ),
      loading: Loading,
      modules: ['Details'],
    }),
  },
  {
    path: AGENT_PROFILE_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ './container/Agent/AccountDetails/AgentDetailsViewPage'
        ),
      loading: Loading,
      modules: ['AgentDetailsViewPage'],
    }),
  },
  {
    path: PRIVACY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy" */ './container/Privacy/Privacy'),
      loading: Loading,
      modules: ['Privacy'],
    }),
  },
  {
    path: PRICING_PLAN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Pricing/Pricing'),
      loading: Loading,
      modules: ['Pricing'],
    }),
  },
  {
    path: ROOM_WIDGET_PREVIEW,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/WidgetsPreview/RoomWidgetPreview'),
      loading: Loading,
      modules: ['Pricing'],
    }),
  },
  {
    path: MEETING_ROOM_WIDGET_PREVIEW,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/WidgetsPreview/MeetingRoomWidgetPreview'),
      loading: Loading,
      modules: ['Pricing'],
    }),
  },
];


/**
 *
 * Protected Route Component
 *
 */
const AddListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddListing" */ './container/AddListing/AddListing'
    ),
  loading: Loading,
  modules: ['AddListing'],
});

const AgentAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ './container/Agent/AccountSettings/AgentAccountSettingsPage'
    ),
  loading: Loading,
  modules: ['AgentAccountSettingsPage'],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound'],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <ProtectedRoute path={ADD_HOTEL_PAGE} component={AddListing} />
        <ProtectedRoute
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          component={AgentAccountSettingsPage}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
