import React, { createContext } from "react";
import { useMemo } from "react";
import { useContext } from "react";

export const availableLanguages = [
    'es', 'en', 'fr', 'it', 'de'
];

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

const lang = (l_lang) => {
    switch (l_lang) {
        case ["it", "it-CH"].includes(l_lang) ? l_lang : "en":
            return "it";
        case ["fr-FR", "fr", "fr-BE", "fr-CA", "fr-CH", "fr-LU"].includes(l_lang) ? l_lang : "en":
            return "fr";
        case ["de", "de-DE", "de-CH", "de-AT", "de-LU", "de-LI"].includes(l_lang) ? l_lang : "en":
            return "de";
        case ["es", "es-AR", "es-GT", "es-CR", "es-PA", "es-DO", "es-MX", "es-VE", "es-CO",
            "es-PE", "es-EC", "es-CL", "es-UY", "es-PY", "es-BO", "es-SV", "es-HN", "es-NI",
            "es-PR", "es-ES"].includes(l_lang) ? l_lang : "en":
            return "es";
        default:
            return "en";
    }
}

var l_lang;
if (navigator.userLanguage) // Explorer
    l_lang = navigator.userLanguage;
else if (navigator.language) // FF
    l_lang = navigator.language;

const initialState = {
    selectedLang: lang(l_lang)
};

function getConfig() {
    const ls = localStorage.getItem(I18N_CONFIG_KEY);
    if (ls) {
        try {
            return JSON.parse(ls);
        } catch (er) {
            console.error(er);
        }
    }
    return initialState;
}

// Side effect
export function setLanguage(lang) {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
    window.location.reload();
}

const I18nContext = createContext();

export function useLang() {
    return useContext(I18nContext).selectedLang;
}

export function withI18n(Component) {
    class WithI18n extends React.Component {
        static displayName = `WithI18n(${Component.displayName || Component.name})`;

        static contextType = I18nContext;

        render() {
            return <Component {...this.props} menu={this.context} />;
        }
    }

    return WithI18n;
}

export const I18nConsumer = I18nContext.Consumer;

export function I18nProvider({ children }) {
    const lang = useMemo(getConfig, []);

    return (
        <I18nContext.Provider value={lang}>
            {children}
        </I18nContext.Provider>
    );
}
