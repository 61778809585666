import React from "react";
import { shallowEqual, useSelector, } from "react-redux";
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import defaultConfiguration from "settings/defaultConfiguration";

export function BrandConfigProvider({ children }) {

    const { domainFrontConfig } = useSelector(
        ({ frontConfigByDomain }) => ({
            domainFrontConfig: frontConfigByDomain.domainFrontConfig,
        }), shallowEqual
    );

    theme.primary[0] = (domainFrontConfig) ? domainFrontConfig.primaryColor : defaultConfiguration.primaryColor;
    theme.fontFamily = {
        primary: (domainFrontConfig) ? domainFrontConfig.fontFamily : defaultConfiguration.fontFamily,
    };

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
