import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    hotelServices: [],
    hotelService: undefined,
    error: null
};

export const hotelServicesSlice = createSlice({
    name: "hotelServices",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        hotelServicesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.hotelServices = action.payload.hotelServices;
        },
    }
});
