import React from "react";
import { IntlProvider } from "react-intl";
import { useLang } from "./I18nProvider";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import "@formatjs/intl-relativetimeformat/locale-data/de";
import "@formatjs/intl-relativetimeformat/locale-data/fr";
import "@formatjs/intl-relativetimeformat/locale-data/it";

import enMessages from "./messages/en";
import esMessages from "./messages/es";
import deMessages from "./messages/de";
import frMessages from "./messages/fr";
import itMessages from "./messages/it";

const allMessages = {
  en: enMessages,
  es: esMessages,
  de: deMessages,
  fr: frMessages,
  it: itMessages,
};

export function MessagesProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
