import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomBook: null,
    error: null,
    prebookMeta: null,
};

export const roomBookingSlice = createSlice({
    name: "roomBooking",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },
        prebookCreated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.prebookMeta = action.payload.prebookMeta;
        },
        roomBookCreated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomBook = action.payload.roomBook;
        },
        resetState: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomBook = null;
            state.prebookMeta = null;
        }
    }
});
