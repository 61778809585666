import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    hotelSearch: [],
    lastError: null,
};

export const hotelSearchSlice = createSlice({
    name: "hotelSearch",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.hotelSearch = [];
        },

        hotelSearchFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.hotelSearch = action.payload.hotelSearch;
        },
    }
});
