import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    hotelExtraServices: [],
    error: null,
};

export const servicesExtraSlice = createSlice({
    name: "servicesExtra",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        hotelExtraServicesFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.hotelExtraServices = action.payload.hotelExtraServices;
        },
    }
});
