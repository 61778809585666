import { combineReducers } from "redux";
import { availabilitySlice } from "./availability/availabilitySlice";
import { destinationSlice } from "./destination/destinationSlice";
import { frontConfigByDomainSlice } from "./front-config-by-domain/configByDomainSlice";
import { hotelsSlice } from "./hotel/hotelSlice";
import { hotelBannerSlice } from "./hotel-banner/hotelBannerSlice";
import { hotelExtraSlice } from "./hotel-extra/hotelExtraSlice";
import { hotelHoursSlice } from "./hotel-hours/hotelHoursSlice";
import { hotelSearchSlice } from "./hotel-search/hotelSearchSlice";
import { roomBookingSlice } from "./room-booking/roomBookingSlice";
import { roomFindBookingSlice } from "./room-find-booking/roomFindBookingSlice";
import { roomCancelBookingSlice } from "./room-cancel-booking/roomCancelBookingSlice";
import { meetingRoomBookingSlice } from "./meeting-room-booking/meetingRoomBookingSlice";
import { roomPriceSlice } from "./room-price/roomPriceSlice";
import { hotelServicesSlice } from "./hotel-services/hotelServicesSlice";
import { meetingRoomSlice } from "./meeting-room/meetingRoomSlice";
import { meetingRoomPriceSlice } from "./meeting-room-price/meetingRoomPriceSlice";
import { meetingRoomPaymentSlice } from "./meeting-room-payment/meetingRoomPaymentSlice";
import { roomFailedPaymentSlice } from "./room-failed-payment/roomFailedPaymentSlice";
import { roomSuccessPaymentSlice } from "./room-success-payment/roomSuccessPaymentSlice";
import { meetingRoomFailedPaymentSlice } from "./meeting-room-failed-payment/meetingRoomFailedPaymentSlice";
import { meetingRoomSuccessPaymentSlice } from "./meeting-room-success-payment/meetingRoomSuccessPaymentSlice";
import { meetingRoomCancelBookingSlice } from "./meeting-room-cancel-booking/meetingRoomCancelBookingSlice";
import { meetingRoomFindBookingSlice } from "./meeting-room-find-booking/meetingRoomFindBookingSlice";
import { roomTypeServicesSlice } from "./room-type-services/roomTypeServicesSlice";
import { serviceSlice } from "./services/servicesSlice";
import { servicesExtraSlice } from "./services-extra/servicesExtraSlice";
import { hotelLatamSlice } from "./hotel-latam/hotelLatamSlice";

export const rootReducer = combineReducers({
    availability: availabilitySlice.reducer,
    destination: destinationSlice.reducer,
    services: serviceSlice.reducer,
    frontConfigByDomain: frontConfigByDomainSlice.reducer,
    hotel: hotelsSlice.reducer,
    hotelBanner: hotelBannerSlice.reducer,
    hotelExtra: hotelExtraSlice.reducer,
    hotelHours: hotelHoursSlice.reducer,
    hotelSearch: hotelSearchSlice.reducer,
    hotelServices: hotelServicesSlice.reducer,
    roomPrice: roomPriceSlice.reducer,
    roomTypeServices: roomTypeServicesSlice.reducer,
    roomBooking: roomBookingSlice.reducer,
    roomFindBooking: roomFindBookingSlice.reducer,
    roomCancelBooking: roomCancelBookingSlice.reducer,
    meetingRoomBooking: meetingRoomBookingSlice.reducer,
    roomSuccessPayment: roomSuccessPaymentSlice.reducer,
    roomFailedPayment: roomFailedPaymentSlice.reducer,
    servicesExtra: servicesExtraSlice.reducer,
    meetingRoom: meetingRoomSlice.reducer,
    meetingRoomPayment: meetingRoomPaymentSlice.reducer,
    meetingRoomSuccessPayment: meetingRoomSuccessPaymentSlice.reducer,
    meetingRoomFailedPayment: meetingRoomFailedPaymentSlice.reducer,
    meetingRoomPrice: meetingRoomPriceSlice.reducer,
    meetingRoomFindBooking: meetingRoomFindBookingSlice.reducer,
    meetingRoomCancelBooking: meetingRoomCancelBookingSlice.reducer,
    hotelLatam: hotelLatamSlice.reducer,
});
