import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomTypeServices: [],
    roomTypeAvailabilityService: undefined,
    error: null
};

export const roomTypeServicesSlice = createSlice({
    name: "roomTypeServices",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        roomTypeAvailabilityServiceFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomTypeServices = action.payload.roomTypeServices;
        },
    }
});
