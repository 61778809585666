import React from 'react';
import { Menu } from 'antd';
import {
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
} from 'react-icons/fa';

const buildHTTPUrl = (source, networkPrefix) => {
  const starWithHttpRE = /(http[s]?:)\/\/[a-z-.]*\/.*/
  if (starWithHttpRE.test(source)) {
    return source;
  }
  return `${networkPrefix}${source}`;
}

const FooterMenu = (props) => {
  const { socialMedia } = props;
  const { facebook, linkedin, twitter, youtube } = socialMedia;
  return (
    <Menu>
      {facebook ? <Menu.Item key="0">
        <a href={facebook} target="_blank">
          <FaFacebookF />
        </a>
      </Menu.Item> : ''}
      {linkedin ? <Menu.Item key="1">
        <a href={linkedin} target="_blank">
          <FaLinkedinIn />
        </a>
      </Menu.Item> : ''}
      {twitter ? <Menu.Item key="2">
        <a href={twitter} target="_blank">
          <FaTwitter />
        </a>
      </Menu.Item> : ''}
      {youtube ? <Menu.Item key="3">
        <a href={youtube} target="_blank">
          <FaYoutube />
        </a>
      </Menu.Item> : ''}
    </Menu>
  );
};

export default FooterMenu;
