import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomPrice: [],
    error: null
};

export const roomPriceSlice = createSlice({
    name: "roomPrice",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        roomPriceFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomPrice = action.payload.roomPrice;
        },

    }
});
