import React from 'react';
import Logo from 'components/UI/FooterLogo/Logo';
import Footers from 'components/Footer/Footer';
import FooterMenu from './FooterMenu';

const Footer = (props) => {
  const { siteConfigs } = props;
  const { logoUrl, hotelName, footer, facebook, linkedin, twitter, youtube, contactInformation } = siteConfigs;
  const socialMedia = {
    facebook: facebook,
    linkedin: linkedin,
    twitter: twitter,
    youtube: youtube
  }
  return (
    <Footers
      logo={<Logo withLink linkTo="/" src={logoUrl} title={hotelName} />}
      menu={(facebook || linkedin || twitter || youtube) ? <FooterMenu socialMedia={socialMedia} /> : <></>}
      text={footer}
      contact={contactInformation}
    />
  );
};

export default Footer;
