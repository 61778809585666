import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const LogoArea = styled.div`
  display: flex;
  align-items: center;
  width:100%;

  a {
    display: flex;
    align-items: center;
    width:100%;
  }

  img {
    width: 50px;
  }

  h3 {
    color: ${themeGet('primary.0', '#008489')};
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 0 10px;
  }
`;



export const HeaderText = styled.div`
  color: ${themeGet('text.0', '#2C2C2C')};
  width:100%;
  text-align:center;
  h3 {
    color: ${themeGet('primary.0', '#008489')};
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 0 10px;
  }
`;

export default LogoArea;
