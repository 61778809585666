import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    hotelBanner: undefined,
    lastError: null
};

export const hotelBannerSlice = createSlice({
    name: "hotelBanner",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        hotelBannerFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.hotelBanner = action.payload.hotelBanner;
        },
    }
});
