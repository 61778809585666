import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomFindBooking: null,
    error: null,
    roomBookingError: false
};

export const roomFindBookingSlice = createSlice({
    name: "roomFindBooking",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
            state.roomBookingError = true;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.roomBookingError = false;
        },

        roomFindBookingFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomFindBooking = action.payload.roomFindBooking;
            state.roomBookingError = false;
        },
    }
});
