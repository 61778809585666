import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomSuccessPayment: null,
    error: null
};

export const roomSuccessPaymentSlice = createSlice({
    name: "roomSuccessPayment",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        roomSuccessPaymentFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomSuccessPayment = action.payload.hotelSuccessPayment;
        },
    }
});
