import React from "react";
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';
import { useLang } from "./I18nProvider";

export function AntdConfigProvider({ children }) {
    const lang = useLang();
    const locales = {
        en: enUS,
        es: esES
    };
    const locale = locales[lang];

    return (
        <ConfigProvider locale={locale}>
            {children}
        </ConfigProvider>
    );
}
