import React from 'react';
import PropTypes from 'prop-types';
import FooterWrapper, {
  MenuWrapper,
  FooterText,
  SecondaryFooter,
  ContactText,
} from './Footer.style';

const Footer = ({ menu, bgSrc, copyright, className, path, text, contact }) => {
  return (
    <>
      <FooterWrapper id="footer" className={className} bg-img={bgSrc}>
        {menu && <MenuWrapper>{menu}</MenuWrapper>}
        {text && <FooterText>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </FooterText>}
        {contact && <ContactText>
          <div>{contact}</div>
        </ContactText>}
      </FooterWrapper>
      {!!path && <SecondaryFooter />}

    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
