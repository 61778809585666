import React, { useContext } from 'react';
import Sticky from 'react-stickynode';
import { withRouter } from 'react-router-dom';
import Logo from 'components/UI/Logo/Logo';
import Navbar from 'components/Navbar/Navbar';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import MainMenu from './MainMenu';
import LanguageMenu from './LanguageMenu';
import CurrencyMenu from './CurrencyMenu';
import HeaderWrapper, {
  MobileNavbar,
  LogoArea,
  LogoWrapper,
  HeaderText,
} from './Header.style';

import { MenuArea, LanguageWrapper, CurrencyWrapper } from 'components/Navbar/Navbar.style';
import { HOME_PAGE, MEETING_ROOM_HOTELS } from 'settings/constant';

export default withRouter(function Header({ location, website, logoUrl, headerText }) {
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(AuthContext);
  const { width } = useWindowSize();

  const headerType = ((location.pathname === HOME_PAGE) || (location.pathname === MEETING_ROOM_HOTELS))
    ? 'transparent'
    : 'default';

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {
                  headerType === 'transparent' ?
                    <LogoWrapper>
                      <a href={website}><img width={50} src={logoUrl} alt={headerText}></img></a>
                      <HeaderText ><h3><a href={website}>{headerText}</a></h3></HeaderText>
                    </LogoWrapper> :
                    <Logo
                      withLink
                      linkTo={website}
                      src={logoUrl}
                      title={headerText}
                    />
                }

              </>
            }
            navMenu={<MainMenu website={website} />}
            languageMenu={<LanguageMenu />}
            currencyMenu={<CurrencyMenu />}
            isLogin={loggedIn}
            headerType={headerType}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
            <MobileNavbar className={headerType}>
              <LogoArea>
                <>
                  {headerType === 'transparent' ? <LogoWrapper>
                    <a href={website}><img width={50} src={logoUrl} alt={headerText}></img></a>
                    <HeaderText ><h3><a href={website}>{headerText}</a></h3></HeaderText>
                  </LogoWrapper> :
                    <Logo
                      withLink
                      linkTo={website}
                      src={logoUrl}
                      title={headerText}
                    />}
                </>
              </LogoArea>
              <MenuArea>
                <LanguageWrapper>
                  <LanguageMenu />
                </LanguageWrapper>
                <CurrencyWrapper>
                  <CurrencyMenu />
                </CurrencyWrapper>
              </MenuArea>
            </MobileNavbar>
          )}
      </Sticky>
    </HeaderWrapper>
  );
});
